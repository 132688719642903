import * as React from 'react';
import { Link } from 'gatsby';

import { Text, BlogImage, BlogLink, BlogLiterature } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import children from '../../../images/educational-corner/blog26/djeca.jpg';
import kokolingoShop from '../../../images/educational-corner/blog26/kokolingo-trgovina.png';
import treasureHunt from '../../../images/educational-corner/blog26/potraga-za-blagom.jpg';

const EnableGreatSchoolStart = () => {
  return (
    <PageNew
      title="KOKOLINGO - Omogućite svome djetetu dobar start u školi"
      description="Polazak u školu jedan je od najvažnijih događaja u životu svakog djeteta. 
      Dolaze nove prilike za druženja, novi prijatelji, nova učiteljica,… 
      Mi roditelji često razmišljamo što sve možemo učiniti kako bismo svome djetetu 
      pomogli ostvariti njegov puni potencijal koji nosi u sebi."
      headline="Omogućite svome djetetu dobar start u školi"
    >
      <Text as="p">
        Polazak u školu jedan je od najvažnijih događaja u životu svakog
        djeteta. Dolaze nove prilike za druženja, novi prijatelji, nova
        učiteljica,… Mi roditelji često razmišljamo što sve možemo učiniti kako
        bismo svome djetetu pomogli ostvariti njegov puni potencijal koji nosi u
        sebi.
      </Text>
      <BlogImage
        imgAlt="Dijeca koja se druze"
        imgTitle="Dijeca koja se druze"
        imgSrc={children}
      />
      <Text as="p">
        Jedna od važnijih stvari na koju često ne obraćamo pažnju jest kako
        dijete komunicira s vršnjacima i odraslim osobama.
      </Text>
      <Text as="p">
        Ako dijete prilikom polaska u školu ima poremećaje izgovora, moguće je
        da mu se dogode mnoge nepoželjne situacije koje mogu djelovati na njegov
        psihofizički razvoj, prvenstveno na samopouzdanje.
      </Text>
      <Text as="p">
        Djetetovo samopouzdanje je važno, jer, ovisno o tome ima li dijete
        visoko ili nisko samopouzdanje, to bi uvelike moglo utjecati na to kakve
        će ocjene imati, tj. kakav će biti njegov školski uspjeh. Misli li
        dijete o sebi da je sposobno izvršiti pojedini zadatak koji mu zadaje
        učiteljica (visoko samopouzdanje) ili smatra da to neće moći postići
        (nisko samopouzdanje), sve to može imati značajne posljedice na njegove
        ocjene, jer djeca s višim samopouzdanjem vjerojatnije će imati bolje
        (odlične) ocjene.
      </Text>
      <Text as="p">
        Samopouzdanje se razvija od malih nogu. Prvenstveno ga razvijaju
        roditelji, zatim uža obitelji, a kasnije, kako djeca odrastaju, važno
        mjesto u razvoju samopouzdanja zauzimaju i vršnjaci. Djeci postaje važno
        što će o njima misliti pojedini prijatelji. Mi odrasli znamo da se djeca
        ponašaju kao djeca, a to znači da su, između ostaloga, sklona isticati i
        naglašavati različitost. Samim time, mogu biti sklona zadirkivati i
        ismijavati nekoga samo zato jer, primjerice, nepravilno izgovara glas R.
      </Text>
      <Text as="p">
        Zadirkivanje i ismijavanje spada u kategoriju verbalnog zlostavljanja.
        Zlostavljanje može ostaviti kratkoročne i dugoročne posljedice na
        fizičkom, emocionalnom, intelektualnom i društvenom polju. Neke od
        posljedica mogu biti slabiji školski uspjeh, lošiji socijalni odnosi,
        razvoj depresivnog i anksioznog poremećaja te slabije samopoštovanje i
        samopouzdanje.
      </Text>
      <Text as="p">
        Također, djeca mogu imati i niže samopouzdanje, jer mogu početi sumnjati
        u sebe, misliti da nisu jednako dobri i vrijedni kao drugi vršnjaci.
        Sumnja u vlastite sposobnosti mogla bi dovesti do toga da se dijete
        povuče, izolira od drugih vršnjaka, ne javlja se za riječ, ne priča
        ispred cijelog razreda i sl. Posljedično, moguće je da dijete počne
        dobivati niže ocjene od onoga za što je sposobno.
      </Text>
      <Text as="p">
        Generalno govoreći, djeca s nižim samopouzdanjem mogu biti sramežljiva,
        povučena, imati strah od bliskosti s ljudima, nemogućnost da se izbore
        za svoja prava. Također, istraživanja navode kako je niže samopouzdanje
        povezano s nižim ocjenama, depresijom i anksioznošću.
      </Text>
      <Text as="h2">
        Što kao roditelji možemo napraviti kako bismo pomogli svome djetetu
      </Text>
      <Text as="p">
        Prvenstveno budimo uz svoje dijete i pomažimo mu u svakom koraku
        njegovog rasta i razvoja. Šaljimo mu ohrabrujuće poruke kako vjerujemo u
        njega, kako mislimo da ono može preskočiti prepreku koja se trenutno
        nalazi pred njime.
      </Text>
      <Text as="p">
        Roditelji često misle da čine uslugu djetetu ako nešto naprave umjesto
        njega (ako je dijete mlađe, vežu tenisicu umjesto njega ili ako je
        starije, napišu zadaću umjesto djeteta, jer je baš umorno od napornoga
        dana), ali tim se postupcima djetetu zapravo radi „medvjeđa usluga“.
        Pustimo dijete da samo obavlja stvari koje razvojno trenutno može
        napraviti. Naravno, dijete će raditi pogreške, ali tko je radio i nikada
        nije pogriješio?
      </Text>
      <Text as="p">
        Važno je jačati samopouzdanje djece, jer djeca s visokim samopouzdanjem
        češće su i sretnija, zadovoljnija sobom, otpornija na stres,
        ambicioznija, lakše podnose kritiku, vole surađivati s drugima,
        postavljati pitanja u školi te su generalno zadovoljnija školom.
      </Text>
      <Text as="p">
        Jedan od načina kako možemo pomoći svome djetetu da se bolje osjeća u
        vlastitoj koži i da ima veće samopouzdanje je i taj da mu pomognemo
        prevladati poremećaj izgovora.
      </Text>
      <Text as="p">
        <BlogLink>Kokolingo</BlogLink> je namijenjen baš ovoj vrsti poteškoća.
      </Text>
      <Text as="h2">Kako kokolingo može pomoći mojem djetetu</Text>
      <Text as="p">
        Digitalna logopedska vježbenica <BlogLink>Kokolingo </BlogLink>
        namijenjena je djeci s artikulacijskim poremećajima izgovora. Djeci
        pruža konkretan napredak u korekciji nepravilnog izgovora glasova uz
        vrlo zabavne aktivnosti. Velika je prednost to što možete s djetetom
        vježbati kod kuće, a dovoljno je vježbati 30 minuta dnevno.
        <BlogLink> Kokolingo </BlogLink>
        je djeci posebno zanimljiv, jer kroz igru mogu vježbati i napredovati u
        korekciji nepravilnog izgovora glasova. Vježbe kroz koje prolaze su
        interaktivne, zanimljive i uzbudljive.
      </Text>
      <Text as="p">
        Svima nam je potrebna podrška kada nam je teško i kada ulažemo dodatne
        napore kako bismo postigli cilj koji smo si postavili. Na sreću djece i
        obitelji, program Kokolingo je osmišljen i za osnaživanje i jačanje
        samopouzdanja kod djece. Tome najbolje svjedoči papigica Koko koja svako
        malo preleti preko ekrana te pohvaljuje i daje podršku djeci koja
        ustraju u vježbama. Na takav način papigica Koko dodatno potiče
        motivaciju i razvoj samopouzdanja.
      </Text>
      <BlogImage
        imgAlt="Kokolingo trgovina gdje mozete obuci papigu Koko"
        imgTitle="Kokolingo trgovina"
        imgSrc={kokolingoShop}
      />
      <Text as="p">
        Ovisno o odabranom Kokolingo paketu, aplikacija ima i različiti{' '}
        <Link
          to="/#cijene-za-roditelje"
          target="_blank"
          hrefLang="hr"
          rel="noopener noreferrer"
        >
          <b>indeks motivacije</b>
        </Link>{' '}
        za dijete. Indeks motivacije predstavlja kombinaciju dvaju faktora,
        učestalosti pojavljivanja papigice Koko s motivacijskim i ohrabrujućim
        porukama za dijete i izbora artikala u trgovini Koko gdje djeca svoje
        novčiće zaslužene vježbanjem mogu zamijeniti za stvari kojima će urediti
        svoju papigicu. O indeksu motivacije izravno ovisi zainteresiranost i
        motiviranost djeteta za korištenje aplikacije. Tako osnovni ili Mini
        paket ima indeks motivacije 15, Smart paket, sa srednjim izborom
        artikala u Koko trgovini i srednje velikom učestalošću pojavljivanja
        papigice Koko ima indeks motivacije 30, a najveći, Premium paket, ima i
        najveći indeks motivacije, 60.
      </Text>
      <BlogImage
        imgAlt="Kokolingo igra potraga za blagom"
        imgTitle="Potraga za blagom"
        imgSrc={treasureHunt}
      />
      <Text as="p">
        Nadamo se da će i vaše dijete dočekati svoju prvu školsku godinu spremno
        i u novom okruženju ostvariti svoj puni potencijal. Na tom putu i mi vam
        možemo pomoći vježbenicom Kokolingo, jer nama je važno da djeca budu
        razigrana, sretna, ali i puna povjerenja u sebe.
      </Text>
      <Text as="p">
        Dario Antić, magistar psihologije, specijalizant transakcijske analize,
        praktičar terapije igrom
      </Text>
      <BlogLiterature>
        <ul>
          <li>
            Beane, A.L. (2008).{' '}
            <i>
              Učionica bez zlostavljanja: više od 100 savjeta i strategija
              namijenjenih učiteljima u školama
            </i>
            . Zagreb: Mosta
          </li>
          <li>
            Bilić, V., Buljan Flander, G., Hrpka, H. (2012).{' '}
            <i>Nasilje nad djecom i među djecom</i>. Jastrebarsko: Naklada Slap.
          </li>
          <li>
            Burić, I., Macuka, I., Sorić, I., Vulić-Prtorić, A. (2007).
            Samopoštovanje u ranoj adolescenciji: važnost uloge roditeljskoga
            ponašanja i školskoga dostignuća.{' '}
            <i>Društvena istraživanja: časopis za opća društvena pitanja</i>.
            17(4-5), 887-906.
          </li>
          <li>
            Oblačić, I., Velki, T., Cakić, L. (2015). Odnos samopoštovanja i
            socijalnog statusa kod učenika nižih razreda osnovne škole.{' '}
            <i>Školski vjesnik</i> 64(1), 153-172.
          </li>
          <li>
            Miljković, D., Rijavec, M. (2012).{' '}
            <i>Razgovori sa zrcalom: psihologija samopouzdanja</i>. Zagreb:
            IEP-D2.
          </li>
        </ul>
      </BlogLiterature>
      <SectionBlog />
    </PageNew>
  );
};

export default EnableGreatSchoolStart;
